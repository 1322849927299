<template>
  <div class="row justify-center items-start bg-grey-3" style="height: calc(100vh - 83px);">
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section class="row justify-end">
        <q-input outlined label="Cari Mapel" style="max-width:300px">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section horizontal>
        <q-card-section class="column q-gutter-md q-pt-none" style="min-width:300px">
          <!-- <q-select
            dense
            outlined
            v-model="selPeriod"
            label="Periode"
            :options="period"
          />-->
          <q-select dense outlined v-model="selKelas" label="Kelas" :options="kelas" />
          <q-select dense outlined v-model="selMapel" label="Mata Pelajaran" :options="mapel" />
          <q-select dense outlined v-model="selSiswa" label="Siswa" :options="siswa" />
          <div>
            <p class="row justify-between">
              <a>Nilai Terakhir:</a>
              <a>50</a>
            </p>
            <p class="row justify-between q-mb-none">
              <a>KKM:</a>
              <a>75</a>
            </p>
          </div>
          <q-input dense outlined type="number" v-model="newNilai" label="Nilai Terbaru" />
          <q-btn filled color="primary" label="SIMPAN"></q-btn>
        </q-card-section>
        <q-markup-table
          flat
          bordered
          class="stickyTable bg-indigo-1 full-width"
          style="height: calc(100vh - 267px);"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th>No</th>
              <th>Tanggal</th>
              <th>Mata Pelajaran</th>
              <th>Nilai Asli</th>
              <th>Nilai Remedial</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in 10" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ i + 1 }}-02-2020</td>
              <td>val</td>
              <td>{{ i + 60 }}</td>
              <td>{{ i + 80 }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      catatan: "",
      kelas: ["10: Qurthuba", "10: Ligno", "10: San andreas"],
      selKelas: "",
      selPeriod: "",
      newNilai: 0,
      period: [
        { label: "2020 - Semester 1", value: 1 },
        { label: "2020 - Semester 2", value: 2 },
        { label: "2021 - Semester 1", value: 3 },
        { label: "2022 - Semester 2", value: 4 }
      ],
      selSiswa: "",
      siswa: [
        { label: "Siswa 1", value: 1 },
        { label: "Siswa 2", value: 2 },
        { label: "Siswa 3", value: 3 }
      ],
      kelas: ["10: Qurthuba", "10: Ligno", "10: San andreas"],
      selKelas: "",
      mapel: ["Matematika", "IPA", "Bahasa"],
      selMapel: ""
    };
  },
  mounted() {},
  /* computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  }, */
  methods: {}
};
</script>

<style lang="scss" scoped></style>
